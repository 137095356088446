const ACTIONS = {
  FETCH_USER_REQUEST: "FETCH_USER_REQUEST",
  FETCH_USER_SUCCESS: "FETCH_USER_SUCCESS",
  FETCH_USER_FAIL: "FETCH_USER_FAIL",
  FETCH_EVENTS: "FETCH_EVENTS",
  FETCH_EVENTS_SUCCESS: "FETCH_EVENTS_SUCCESS",
  FETCH_EVENTS_FAIL: "FETCH_EVENTS_FAIL",

  SIGN_UP: "SIGN_UP",
  LOGOUT: "LOGOUT",

  SAVE_USER_LOGIN: "SAVE_USER_LOGIN",
  SET_EVENTS: "SET_EVENTS",

  FETCH_MASTER_CONFIG: "FETCH_MASTER_CONFIG",
  SET_MASTER_CONFIG: "SET_MASTER_CONFIG",
  FETCH_MUN: "FETCH_MUN",
  SET_MUN: "SET_MUN",
};

export default ACTIONS;
