import React from 'react'
import './loader.css'

export default function Loader() {
    return (
        
        <div className='loader_entry'>
            <div class="lds-roller">
                <div></div><div></div><div></div><div></div><div></div><div></div><div>
                    </div><div></div>
            </div>
        </div>
            
        
    )
}