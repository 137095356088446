import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./Carousel.css";
import Product from "./Product";
import axios from "axios";
import { connect } from "react-redux";
import CarouselCard from "./CarouselCard";
const Carousel1 = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const [events, setevents] = useState([]);
  useEffect(() => {
    const fetchdata = async () => {
      const response = await axios.get("https://api2.thomso.in/apiV1/event");
      // console.log(response);
      console.log(response.data);
      setevents(response.data);
    };
    fetchdata();
  }, []);
  const productData = [
    {
      id: 1,
      imageurl:
        "https://images.unsplash.com/photo-1560769629-975ec94e6a86?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fHByb2R1Y3RzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      name: "Colorful sneakers",
      price: "$19.99",
      description: "Some text about the product..",
    },
    {
      id: 2,
      imageurl:
        "https://images.unsplash.com/photo-1542291026-7eec264c27ff?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cHJvZHVjdHN8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
      name: "Sport sneakers",
      price: "$21.99",
      description: "Some text about the product..",
    },
    {
      id: 3,
      imageurl:
        "https://images.unsplash.com/photo-1546868871-7041f2a55e12?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjZ8fHByb2R1Y3RzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      name: "iWatch",
      price: "$99.99",
      description: "Some text about the product..",
    },
    {
      id: 4,
      imageurl:
        "https://images.unsplash.com/photo-1610824352934-c10d87b700cc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjl8fHByb2R1Y3RzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      name: "Water Bottle",
      price: "$14.99",
      description: "Some text about the product..",
    },
    {
      id: 5,
      imageurl:
        "https://images.unsplash.com/photo-1525966222134-fcfa99b8ae77?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzB8fHByb2R1Y3RzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      name: "Vans sneakers",
      price: "$38.99",
      description: "Some text about the product..",
    },
    {
      id: 6,
      imageurl:
        "https://images.unsplash.com/photo-1585386959984-a4155224a1ad?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzV8fHByb2R1Y3RzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      name: "Coco Noir",
      price: "$149.99",
      description: "Some text about the product..",
    },
    {
      id: 7,
      imageurl:
        "https://images.unsplash.com/photo-1589782182703-2aaa69037b5b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OTJ8fHByb2R1Y3RzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      name: "Sunglasses",
      price: "$38.99",
      description: "Some text about the product..",
    },
    {
      id: 8,
      imageurl:
        "https://images.unsplash.com/photo-1625772452859-1c03d5bf1137?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fHByb2R1Y3RzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      name: "Dove cream",
      price: "$49.99",
      description: "Some text about the product..",
    },
  ];
  // const product = events
  //   .filter((el) => {
  //     return el.is_payment === true && el.name !== "SILENT DJ";
  //   })
  //   .map((item) => <Product url={item.image} />);
  const product = productData.map((item) => <Product url={item.imageurl} />);
  return (
    <div id="white_div">
      <div className="car_head_txt">Participate in more fun events</div>
      {/* {events
        .filter((el) => {
          return el.is_payment === true && el.name !== "SILENT DJ";
        })
        .map((item) => (
          <CarouselCard  />
        ))} */}
      <div className="payCarousel" >
        {events.filter((el) => {
          return el.is_payment === true;
        }).slice(0,4).map((item) => (
          <CarouselCard data={item} />
        ))}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  let events = state.user.total_events;

  return {
    events,
  };
};
export default connect(mapStateToProps, null)(Carousel1);
